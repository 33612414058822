import { createAction, props } from '@ngrx/store';
import { ID } from '../../../../core/definitions/types';
import { ElementDefinitionDetail } from '../../../../core/interfaces/element-definition-detail.interface';
import { ElementDefinitionSummary } from '../../../../core/interfaces/element-definition.summary';
import { FieldDefinitionDetail } from 'src/app/core/interfaces/field-definition-detail.interface';
import { ElementHistoryItem } from 'src/app/core/interfaces/element-history-item.interface';
import { UsageInfo } from 'src/app/core/interfaces/validation-result.interface';
import { ElementsActions } from 'src/app/modules/shared-nodes/interfaces/elements-actions';
import { ElementDifferences } from 'src/app/core/interfaces/element-differences.interface';
import { GeneralValidationResult } from 'src/app/core/interfaces/general-validation-result.interface';

export const getElementDefinitions = createAction(
    '[ElementDefinitions API] GET ElementDefinitions'
);
export const getElementDefinitionsSuccess = createAction(
    '[ElementDefinitions API] GET ElementDefinitions Success',
    props<{
        elementDefinitions: ElementDefinitionSummary[];
        elementDefinitionDrafts: ElementDefinitionDetail[];
    }>()
);
export const getElementDefinitionsFail = createAction(
    '[ElementDefinitions API] GET ElementDefinitions Fail',
    props<{ error: string | null }>()
);
export const upsertElementDefinitionSuccess = createAction(
    '[ElementDefinitions API] CREATE ElementDefinition Success',
    props<{
        draftId: ID;
        elementDefinition: ElementDefinitionDetail;
        creationMode: boolean;
    }>()
);

export const getElementDefinitionById = createAction(
    '[ElementDefinitions API] GET ElementDefinition by Id',
    props<{ id: ID; historyCollection: ID }>()
);
export const getElementDefinitionByIdSuccess = createAction(
    '[ElementDefinitions API] GET ElementDefinition by Id Success',
    props<{ elementDefinition: ElementDefinitionDetail; isDraft?: boolean }>()
);

export const getElementDefinitionByIdFail = createAction(
    '[ElementDefinitions API] GET ElementDefinition by Id Fail',
    props<{ error: string | null }>()
);

export const setElementDefinitionHistory = createAction(
    '[ElementDefinitions API] SET ElementDefinition history',
    props<{ elementDefinitionHistory: ElementHistoryItem[] }>()
);

export const upsertElementDefinition = createAction(
    '[ElementDefinitions API] CREATE ElementDefinition',
    props<{
        elementDefinition: ElementDefinitionDetail;
    }>()
);

export const upsertElementDefinitionFail = createAction(
    '[ElementDefinitions API] CREATE ElementDefinition Fail',
    props<{ error: string | null }>()
);

export const deleteElementDefinition = createAction(
    '[ElementDefinitions API] DELETE ElementDefinition',
    props<{ id: ID; historyCollection: ID }>()
);

export const deleteElementDefinitionFail = createAction(
    '[ElementDefinitions API] DELETE ElementDefinition Fail',
    props<{ error: string | null }>()
);
export const deleteElementDefinitionSuccess = createAction(
    '[ElementDefinitions API] DELETE ElementDefinition Success',
    props<{ id: ID }>()
);

export const getFieldDefinitions = createAction(
    '[ElementDefinitions API] GET FieldDefinitions'
);

export const getFieldDefinitionsSuccess = createAction(
    '[ElementDefinitions API] GET FieldDefinitions Success',
    props<{ fieldDefinitions: FieldDefinitionDetail[] }>()
);

export const getFieldDefinitionsFail = createAction(
    '[ElementDefinitions API] GET FieldDefinitions Fail',
    props<{ error: string | null }>()
);

export const getCodeNameUsage = createAction(
    '[ElementDefinitions API] GET FieldDefinitions Name and CodeName usage',
    props<{ fieldDefinition: FieldDefinitionDetail; newCodeName: string }>()
);

/**
 * Action that gets response from api about codeName usage
 *
 * {result: string | null} it gets string if there is a ussage or null in case that there is not element using this Field
 */
export const getCodeNameUsageSuccess = createAction(
    '[ElementDefinitions API] GET FieldDefinitions Name and CodeName usage Success',
    props<{ usageInfo: UsageInfo[]; fieldId: ID }>()
);

export const getCodeNameUsageFail = createAction(
    '[ElementDefinitions API] GET FieldDefinitions Name and CodeName usage Fail',
    props<{ error: string | null }>()
);

export const elementActions: ElementsActions = {
    getCodeNameUsage
};

export const getElementDifferences = createAction(
    '[ElementDefinitions API] GET Differences between two FieldDefinitions'
);

export const getElementDifferencesSuccess = createAction(
    '[ElementDefinitions API] GET Differences between two FieldDefinitions Success',
    props<{ elementDifferences: ElementDifferences }>()
);

export const getElementDifferencesFail = createAction(
    '[ElementDefinitions API] GET Differences between two FieldDefinitions Fail',
    props<{ error: string | null }>()
);

export const publishElementDefinition = createAction(
    '[ElementDefinitions] Publish ElementDefinition',
    props<{
        id: ID;
        message: string;
        historyCollection: ID;
    }>()
);


export const fieldChangeResetGeneralErrors = createAction(
    '[ElementDefinitions] field Change Reset General Errors'
);
export const validateElementStructureSuccess = createAction(
    '[ElementDefinitions] Validate element structure Success',
    props<{ result: GeneralValidationResult }>()
);
export const validateElementStructureFail = createAction(
    '[ElementDefinitions] Validate elementstructure fail',
    props<{ errors: string[] }>()
);
