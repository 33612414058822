import { Injectable } from '@angular/core';
import { WorkroadsTreeNode } from '../../../core/interfaces/workroads-tree-node.interface';
import { ID } from '../../../core/definitions/types';
import { AutocadTreeNode } from '../../../core/interfaces/autocad-tree-node.interface';
import { PatternDetail } from '../../../core/interfaces/pattern-detail.interface';
import { uuidWrapper } from 'src/app/shared/helpers/uuid';
import {
    flattenAutocadTree,
    flattenTree,
    flattenWorkRoadsTree
} from 'src/app/core/helpers/pattern-utils';

@Injectable({
    providedIn: 'root'
})
export class DuplicatePatternService {
    duplicate(pattern: PatternDetail): PatternDetail {
        return this.removeIds(pattern);
    }

    removeIds(pattern: PatternDetail): PatternDetail {
        const ids: { [key: ID]: ID } = {};
        [
            ...flattenAutocadTree(pattern.autocadTree?.childrenDefinitions ?? []),
            ...flattenWorkRoadsTree(pattern.workRoadsTree?.childrenDefinitions ?? [])
        ].forEach((node) => {
            if (node.id) {
                ids[node.id] = uuidWrapper.uuid();
            }
        });

        return {
            ...pattern,
            id: null as any,
            tid: pattern.id,
            title: '',
            description: '',
            autocadTree: {
                ...pattern.autocadTree,
                childrenDefinitions: this.removeIdsFromAutocadTree(
                    pattern.autocadTree?.childrenDefinitions ?? [],
                    ids
                ),
                groupCount: pattern.autocadTree?.groupCount ?? {}
            },
            workRoadsTree: {
                ...pattern.workRoadsTree,
                childrenDefinitions: this.removeIdsFromWorkroadsTree(
                    pattern.workRoadsTree?.childrenDefinitions ?? [],
                    ids
                ),
                groupCount: pattern.workRoadsTree?.groupCount ?? {}
            }
        };
    }

    /**
     * Removes node ids and filter ids.
     * Filters are updated from this values when navigating
     * @param childrenDefinitions
     * @param ids
     */
    removeIdsFromAutocadTree(
        childrenDefinitions: AutocadTreeNode[],
        ids: { [key: ID]: ID }
    ): AutocadTreeNode[] {
        return childrenDefinitions?.map((node) => {
            return {
                ...node,
                id: undefined,
                tId: ids[node.id!],
                goTo: node.goTo ? ids[node.goTo] : undefined,
                created: true,
                filters: node.filters.map((filter) => ({
                    ...filter,
                    id: uuidWrapper.uuid(),
                    nodeId: ids[node.id!],
                    isNew: true
                })),
                childrenDefinitions: node.childrenDefinitions
                    ? this.removeIdsFromAutocadTree(node.childrenDefinitions, ids)
                    : []
            };
        });
    }

    /**
     * Removes the ids from nodes and fieldExpressions.
     * @param childrenDefinitions
     * @param ids
     */
    removeIdsFromWorkroadsTree(
        childrenDefinitions: WorkroadsTreeNode[],
        ids: { [key: ID]: ID }
    ): WorkroadsTreeNode[] {
        return childrenDefinitions?.map((node, idx) => {
            return {
                ...node,
                id: undefined,
                tId: ids[node.id!],
                link: node.link ? ids[node.link] : undefined,
                goTo: node.goTo ? ids[node.goTo] : undefined,
                created: true,
                childrenDefinitions: node.childrenDefinitions
                    ? this.removeIdsFromWorkroadsTree(node.childrenDefinitions, ids)
                    : [],
                fieldExpressions: node.fieldExpressions.map(
                    (fieldExpression) =>
                        ({
                            ...fieldExpression,
                            id: undefined
                        } as any)
                )
            } as WorkroadsTreeNode;
        });
    }
}
