import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { cloneDeep } from 'lodash';
import { Subject } from 'rxjs';
import { filter, map, take, takeUntil, withLatestFrom } from 'rxjs/operators';
import { IUnique } from 'src/app/core/definitions/interfaces';
import { elementDefinitionSummaryFromDetail } from 'src/app/core/interfaces/element-definition.summary';
import { FieldDefinitionDetail } from 'src/app/core/interfaces/field-definition-detail.interface';
import { FieldExpressionDefinition } from 'src/app/core/interfaces/field-expression-definition.interface';
import { ViewDetail } from 'src/app/core/interfaces/view-detail.interface';
import { NULL_WORKROADS_TREE, WorkroadsTree } from 'src/app/core/interfaces/workroads-tree.interface';
import { ViewDetailModel } from 'src/app/core/models/view-detail.model';
import { Route } from '../../../core/constants/feature';
import { Status } from '../../../core/definitions/enums';
import { ElementDefinitionDetail } from '../../../core/interfaces/element-definition-detail.interface';
import { PatternDetail } from '../../../core/interfaces/pattern-detail.interface';
import { PatternDetailModel } from '../../../core/models/pattern-detail.model';
import { uuidWrapper } from '../../../shared/helpers/uuid';
import { fromRoot } from '../../../store';
import {
    createElementDefinitionDraft,
    duplicateElementDefinition
} from '../../elements/store/actions/element-definitions.action';
import {
    ElementDraftCreationInfo,
    fromElementDefinitions
} from '../../elements/store/element-definitions.selectors';
import { DEFAULT_REPORT_DEFINITION_LIST } from '../../list-tree-view/constants/report-default.constant';
import { DuplicatePatternService } from '../../patterns/services/duplicate-pattern.service';
import { createPatternDraft } from '../../patterns/store/actions/patterns.action';
import {
    fromPatterns,
    PatternDraftCreationInfo
} from '../../patterns/store/patterns.selectors';
import { ViewsDraftCreationInfo } from '../../views/interfaces/view-draft-creation.interface';
import { DuplicateViewService } from '../../views/services/duplicate-view.service';
import { createViewDraft } from '../../views/store/actions/views.actions';
import { fromViews } from '../../views/store/selectors/views.selectors';
import { createStructureFromElement } from '../../elements/helpers/structure.helpers';

@Injectable({
    providedIn: 'root'
})
export class DraftRoutingService {
    constructor(
        private store: Store,
        private router: Router,
        private duplicatePatternService: DuplicatePatternService,
        private duplicateViewService: DuplicateViewService
    ) { }

    /**
     * Initiates the process of creating a draft.
     * By navigating with a 'new' query parameter, we ensure that route guards
     * and other navigation-based logic is consistently applied.
     *
     * This functionality is also used by the hub to create a draft with the provided source (patterns only)
     *
     * Avoid creating drafts without using this service
     */
    private createDraft(route: Route) {
        this.router
            .navigate([`app/${route}`], {
                queryParams: { new: true },
                queryParamsHandling: 'merge'
            })
            .catch((error) => {
                console.error(error); // eslint-disable-line no-console
            });
    }

    private createDuplicatedDraft(route: Route): void {
        this.router
            .navigate([`app/${route}`], {
                queryParams: { new: true, duplicate: true },
                queryParamsHandling: 'merge' // This will keep the selected pattern in the url before redirecting on success
            })
            .catch((error) => {
                console.error(error); // eslint-disable-line no-console
            });
    }

    elementDraftCreationNavigation$ = this.store
        .select(fromElementDefinitions.selectDraftCreationInfoFromUrl)
        .pipe(
            filter((info): info is ElementDraftCreationInfo => !!info),
            withLatestFrom(
                this.store.select(fromRoot.selectUser).pipe(
                    take(1),
                    filter((user) => !!user),
                    map((user) => user!.userId)
                ),
                this.store.select(fromElementDefinitions.selectCurrentElementDefinition)
            )
        );

    viewDraftCreationNavigation$ = this.store
        .select(fromViews.selectViewDraftCreationInfoFromUrl)
        .pipe(
            filter((info): info is ViewsDraftCreationInfo => !!info),
            withLatestFrom(this.store.select(fromViews.selectSelected))
        );

    draftCreationNavigation$ = this.store
        .select(fromPatterns.selectDraftCreationInfoFromUrl)
        .pipe(
            filter((info): info is PatternDraftCreationInfo => !!info),
            withLatestFrom(this.store.select(fromPatterns.selectSelectedPattern))
        );

    /**
     * This method is used to create a draft when the user navigates to a pattern.
     *
     * If new query param is present it will create a draft with default values
     * If duplicate query param is present it will create a draft with current pattern values
     *
     * The createPatternDraft action will redirect to the newly created draft
     *
     * @param onDestroy$
     */
    createPatternDraftOnNavigation(onDestroy$: Subject<void>): void {
        this.draftCreationNavigation$.pipe(takeUntil(onDestroy$)).subscribe({
            next: ([
                {
                    isDraft,
                    duplicateCurrentPattern,
                    selectedPush,
                    selectedDrawing,
                    autoVersion
                },
                selectedPattern
            ]) => {
                if (!isDraft) return;

                const draft: PatternDetail =
                    duplicateCurrentPattern && selectedPattern
                        ? new PatternDetailModel({
                            ...this.duplicatePatternService.duplicate(
                                cloneDeep(selectedPattern)
                            ),
                            id: uuidWrapper.uuid()
                        })
                        : new PatternDetailModel({
                            id: uuidWrapper.uuid()
                        });

                // If no selected push then it can have the same values as the selected pattern (if duplicate)
                if (selectedPush != null) {
                    draft.selectedPush = selectedPush;
                    draft.selectedDrawing = selectedDrawing;
                    draft.autoVersion = autoVersion;
                }

                this.store.dispatch(
                    createPatternDraft({
                        duplicate: duplicateCurrentPattern,
                        draft
                    })
                );
            }
        });
    }

    createElementDraftOnNavigation(onDestroy$: Subject<void>): void {
        this.elementDraftCreationNavigation$.pipe(takeUntil(onDestroy$)).subscribe({
            next: ([{ isDraft, duplicate }, userId, selectedElement]) => {
                if (!isDraft) return;
                if (duplicate && selectedElement) {
                    const draft: ElementDefinitionDetail = {
                        ...cloneDeep(selectedElement),
                        historyId: undefined,
                        name: '',
                        codeName: '',
                        description: '',
                        status: Status.Draft,
                        isDraft: true,
                        id: uuidWrapper.uuid()
                    };

                    this.store.dispatch(duplicateElementDefinition({ draft }));
                } else {
                    const elementDefinition = {
                        id: uuidWrapper.uuid(),
                        name: '',
                        codeName: '',
                        description: '',
                        admitsChildren: true,
                        elementDefinitions: [],
                        fieldDefinitions: [],
                        isCountable: true,
                        modified: false,
                        unicity: false,
                        quantity: 0,
                        createdAt: new Date(Date.now()),
                        status: Status.Draft,
                        createdBy: userId,
                        message: '',
                        previousVersionId: '',
                        isDraft: true,
                        structure: NULL_WORKROADS_TREE,
                        hasStructure: false
                    } as ElementDefinitionDetail;
                    const draft = {
                        ...elementDefinition,
                        structure: createStructureFromElement(elementDefinition)

                    } as ElementDefinitionDetail;
                    this.store.dispatch(createElementDefinitionDraft({ draft }));
                }
            }
        });
    }

    createViewDraftOnNavigation(onDestroy$: Subject<void>): void {
        this.viewDraftCreationNavigation$.pipe(takeUntil(onDestroy$)).subscribe({
            next: ([{ isDraft, duplicateCurrentView }, selectedView]) => {
                if (!isDraft) return;

                const draft: ViewDetail =
                    duplicateCurrentView && selectedView
                        ? new ViewDetailModel({
                            ...this.duplicateViewService.duplicate(
                                cloneDeep(selectedView)
                            ),
                            id: uuidWrapper.uuid()
                        })
                        : new ViewDetailModel({
                            id: uuidWrapper.uuid(),
                            reportDefinitions: [
                                { ...DEFAULT_REPORT_DEFINITION_LIST, name: 'Report 1' }
                            ]
                        } as IUnique);

                this.store.dispatch(
                    createViewDraft({
                        duplicate: duplicateCurrentView,
                        draft
                    })
                );
            }
        });
    }

    createPatternDraft(): void {
        this.createDraft(Route.patterns);
    }

    createDuplicatePatternDraft(): void {
        this.createDuplicatedDraft(Route.patterns);
    }

    duplicateElementDefinition() {
        this.createDuplicatedDraft(Route.elements);
    }

    createElementDefinitionDraft() {
        this.createDraft(Route.elements);
    }

    createViewDraft(): void {
        this.createDraft(Route.views);
    }

    createDuplicateViewDraft(): void {
        this.createDuplicatedDraft(Route.views);
    }
}

