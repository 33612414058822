import { getSelectors } from '@ngrx/router-store';
import { createSelector } from '@ngrx/store';
import { isArray } from 'lodash';
import { Status, TrackedChange } from 'src/app/core/definitions/enums';
import { ID } from 'src/app/core/definitions/types';
import { flattenValidationStateErrors, treeExpressionErrors } from 'src/app/core/helpers/pattern-utils';
import { FieldDefinitionDetail } from 'src/app/core/interfaces/field-definition-detail.interface';
import { Errors, ErrorType, TreeValidationError, TreeValidationState } from 'src/app/core/interfaces/general-validation-result.interface';
import { WorkroadsTree } from 'src/app/core/interfaces/workroads-tree.interface';
import { Route } from '../../../core/constants/feature';
import { fromRoot } from '../../../store';
import { HeaderItem } from '../../layout/interfaces/header-item';
import { diff } from '../../shared-nodes/helpers/helper-funtions';
import { elementDefinitionsFeature } from './element-definitions.reducer';
import { findDifferenceByProperty, getTransformFunction, groupDrafts } from './helpers';
import {
    CODE_NAME,
    DifferencesResult,
    ElementDefinitionQueryParams,
    ElementToolbarView,
    NAME,
    NAME_AND_CODE_NAME,
    PropertyId,
    ViewElementDifferencesAddedFields
} from './types';
import { fromWorkroadsTreeEditor } from '../../shared-nodes/store/shared-nodes.selector';
import { ElementDefinitionDetail } from 'src/app/core/interfaces/element-definition-detail.interface';

const { selectQueryParams } = getSelectors();

const {
    selectDetail,
    selectCreationMode,
    selectElementDefinitions,
    selectElementDefinitionDrafts,
    selectFieldDefinitions,
    selectCurrent,
    selectModified,
    selectFieldDefinitionValidations,
    selectShowVersionHistory,
    selectElementDefinitionHistory,
    selectDetailHeader,
    selectIsLoadingDetail,
    selectIsLoadingList,
    selectElementDifferences,
    selectStructureValidationResult,
    selectErrorsOnValidation
} = elementDefinitionsFeature;

const selectSelectedQueryParam = createSelector(
    selectQueryParams,
    (queryParams): ElementDefinitionQueryParams => {
        return {
            selected: queryParams.selected,
            historyCollection: queryParams.historyCollection
        };
    }
);

export interface ElementDraftCreationInfo {
    isDraft: boolean;
    duplicate: boolean;
}

const selectDraftCreationInfoFromUrl = createSelector(
    selectQueryParams,
    fromRoot.selectCurrentFeature,
    (params, currentFeature) => {
        if (currentFeature != Route.elements || !params.new) return null;
        return {
            isDraft: true,
            duplicate: !!params.duplicate
        };
    }
);

const selectCurrentElementDefinition = createSelector(
    selectCurrent,
    selectDetailHeader,
    (current, header) => {
        if (!current) return null;
        return {
            ...current,
            ...header
        };
    }
);

const selectCurrentSkippingRemovedFields = createSelector(
    selectCurrentElementDefinition,
    (current) => {
        if (!current) return null;
        return {
            ...current,
            fieldDefinitions: current.fieldDefinitions.filter(
                (field) => field.trackedChange !== TrackedChange.Deleted
            )
        };
    }
);

const selectFilteredFieldDefinitions = createSelector(
    selectFieldDefinitions,
    selectCurrentSkippingRemovedFields,
    (fieldDefinitions, current) => {
        if (!current?.id) return [];
        return fieldDefinitions.filter((field) => {
            return !current.fieldDefinitions.find((f) => f.id === field.id);
        });
    }
);

const selectFieldDefinitionsChanges = (fieldId: ID) =>
    createSelector(
        selectCurrentSkippingRemovedFields,
        selectDetail,
        (current, detail) => {
            const currentField = current?.fieldDefinitions.find(
                (field) => field.id === fieldId
            );
            const detailField = detail?.fieldDefinitions.find(
                (field) => field.id === fieldId
            );
            if (currentField && detailField) {
                const differences = diff(currentField, detailField);
                if (differences.size) return differences;
            }
            return null;
        }
    );

const selectFormattedFieldChanges = (fieldId: ID) => {
    return createSelector(selectFieldDefinitionsChanges(fieldId), (changes): string => {
        if (!changes) return '';
        return [...changes.entries()]
            .map((items) => {
                return `Modified ${items[0]}. Previous value: ${items[1].detail}`;
            })
            .join(', ');
    });
};

const selectDefinitionValidation = (fieldId: ID) =>
    createSelector(selectFieldDefinitionValidations, (validations) => {
        return validations[fieldId] ?? [];
    });

const selectVersions = createSelector(
    selectElementDefinitionHistory,
    (elementDefinitionHistory) => {
        const i = elementDefinitionHistory.findIndex(
            (item) => item.status === Status.Published
        );
        const afterSandbox = groupDrafts(
            elementDefinitionHistory.slice(0, i < 0 ? Number.MAX_VALUE : i)
        );
        let sandbox = null;
        if (afterSandbox.length > 0) {
            if (isArray(afterSandbox[0])) {
                sandbox = afterSandbox[0][0];
                afterSandbox[0].shift();
            }
        }
        return {
            sandbox: sandbox,
            afterSandbox,
            current: i !== -1 ? elementDefinitionHistory[i] : null,
            archives: i != -1 ? groupDrafts(elementDefinitionHistory.slice(i + 1)) : []
        };
    }
);

const selectLastestPublishedVersion = createSelector(
    selectVersions,
    (versions) => versions.current
);

const selectCountVersion = createSelector(
    selectElementDefinitionHistory,
    (elementDefinitionHistory) => {
        return elementDefinitionHistory.length;
    }
);

const selectHeader = createSelector(
    selectCurrent,
    selectDetailHeader,
    (current, detailHeader): HeaderItem | null => {
        if (!current) return null;
        return detailHeader ?? ({ ...current } as HeaderItem);
    }
);

const selectIsViewLoading = createSelector(
    selectIsLoadingDetail,
    selectIsLoadingList,
    (isDetailLoading, isLoadingList) => isDetailLoading || isLoadingList
);

const selectAllowChangeElement = createSelector(
    selectModified,
    selectCreationMode,
    (isModified, isCreationMode) => !isModified || isCreationMode
);

const selectElementToolbarView = createSelector(
    fromRoot.selectUserPermissions,
    selectCurrent,
    selectModified,
    selectCreationMode,
    (permission, currentElement, modified, creationMode): ElementToolbarView => ({
        ableToEdit: permission.isAbleToEditElements,
        currentElement,
        modified,
        creationMode
    })
);

// select filed with the given id from selectElementDifferences addedFields, removedFields, modifiedFields
const selectElementFieldDifferences = (fieldId: ID) =>
    createSelector(
        selectElementDifferences,
        (elementDifferences): ViewElementDifferencesAddedFields => {
            if (elementDifferences === null)
                return {
                    addedFields: null,
                    modifiedFields: null
                };
            const addedFieldsIndex = elementDifferences.addedFields.findIndex(
                (field) => field.id === fieldId
            );
            const addedFields =
                addedFieldsIndex !== -1
                    ? elementDifferences.addedFields[addedFieldsIndex]
                    : null;

            const modifiedFieldsIndex = elementDifferences.modifiedFields.findIndex(
                (field) => field.modified.id === fieldId
            );
            const modifiedFields =
                modifiedFieldsIndex !== -1
                    ? elementDifferences.modifiedFields[modifiedFieldsIndex]
                    : null;
            return {
                addedFields,
                modifiedFields
            };
        }
    );

// select field added from selectElementFieldDifferences
const selectElementDefinitionAddedField = (fieldId: ID) =>
    createSelector(
        selectElementFieldDifferences(fieldId),
        (elementFieldDifferences): boolean => {
            return !!elementFieldDifferences.addedFields;
        }
    );

const selectFieldDifference = (property: PropertyId, fieldId: ID) =>
    createSelector(
        selectElementFieldDifferences(fieldId),
        selectDefinitionValidation(fieldId),
        (elementFieldDifferences, definitionValidation): DifferencesResult | null => {
            // if the property is codeName and there is no usage of the codename, return null
            if (definitionValidation.length === 0 && property === CODE_NAME) return null;

            const transformFn = getTransformFunction(property);
            return findDifferenceByProperty(
                elementFieldDifferences?.modifiedFields?.differences ?? [],
                property,
                true,
                transformFn
            );
        }
    );

const selectDifferences = (property: PropertyId) =>
    createSelector(
        selectElementDifferences,
        (elementDifferences): DifferencesResult | null => {
            const differences = elementDifferences?.elementDifferences ?? [];
            let prop = property;

            // if the property is NAME_AND_CODE_NAME we should check if there is a difference in NAME or CODE_NAME
            // depending that is the message the we should show
            if (property === NAME_AND_CODE_NAME) {
                const filteredDifferences = differences.filter(
                    (d) => d.property === CODE_NAME || d.property === NAME
                );
                if (filteredDifferences.length === 0) return null;
                prop =
                    filteredDifferences.length === 1
                        ? (filteredDifferences[0].property as PropertyId)
                        : property;
            }

            const transformFn = getTransformFunction(prop);
            return findDifferenceByProperty(
                elementDifferences?.elementDifferences ?? [],
                prop,
                false,
                transformFn
            );
        }
    );

// select removedFields from selectElementDifferences
const selectElementDefinitionRemovedFields = createSelector(
    selectElementDifferences,
    (elementDifferences): FieldDefinitionDetail[] =>
        elementDifferences?.removedFields ?? []
);

const selectStructure = createSelector(selectCurrent, (current) => current?.structure);

// Structure errors
const selectStructureExpressionErrors = createSelector(
    selectStructure,
    (tree?: WorkroadsTree): Errors | null =>
        tree
            ? treeExpressionErrors(tree)
            : { errors: [], warnings: [] }
);


const selectTopErrorNotification = createSelector(
    selectStructureValidationResult,
    selectErrorsOnValidation,
    (validationResult, errors): string[] => {
        const result = new Set<string>();

        if (validationResult?.globalErrors.errors) {
            validationResult.globalErrors.errors.forEach((error) =>
                result.add(error.errorMessage)
            );
        }

        errors.forEach((error) => result.add(error));

        return [...result];
    }
);

const selectStructureErrorsAndDifferences = createSelector(
    selectStructureValidationResult,
    selectDifferences(NAME_AND_CODE_NAME),
    (allPatternsErrors, difference): TreeValidationState | null => {
        if (!difference) {
            return allPatternsErrors;
        }

        return {
            ...allPatternsErrors,
            globalErrors: {
                errors: [...allPatternsErrors?.globalErrors.errors ?? [], {
                    errorMessage: difference.message,
                    errorType: ErrorType.UNKNOWN,
                    fieldId: '',
                    nodeId: ''
                }],
                warnings: allPatternsErrors?.globalErrors.warnings ?? [],

            }
        } as TreeValidationState;

    }
)

const selectAllPatternErrors = createSelector(
    selectStructureErrorsAndDifferences,
    selectErrorsOnValidation,
    selectStructureExpressionErrors,
    (validationResult, validationErrors, expressionErrors): TreeValidationError[] => {
        const totalErrors: TreeValidationError[] = [];
        const totalWarnings: TreeValidationError[] = [];
        if (validationResult) {
            const { errors, warnings } = flattenValidationStateErrors(
                validationResult,
                false
            );
            totalErrors.push(...errors);
            totalWarnings.push(...warnings);
        }

        totalErrors.push(
            ...validationErrors.map((error) => ({
                errorMessage: error,
                errorType: ErrorType.UNKNOWN,
                fieldId: '',
                nodeId: ''
            }))
        );

        totalErrors.push(...(expressionErrors?.errors ?? []));
        totalWarnings.push(...(expressionErrors?.warnings ?? []));
        return totalErrors;
    }
);

const selectNodesWithErrors = createSelector(
    selectAllPatternErrors,
    (allErrors): Set<ID> => {
        const nodesWithErrors = new Set<ID>();
        allErrors.forEach((error) => {
            if (error.nodeId) {
                nodesWithErrors.add(error.nodeId);
            }
        });
        return nodesWithErrors;
    }
);


const selectElementsForStructure = createSelector(
    fromWorkroadsTreeEditor.selectElementDefinitions,
    selectCurrent,
    (elementDefinitions: ElementDefinitionDetail[], currentElement: ElementDefinitionDetail | null): ElementDefinitionDetail[] => {
        return elementDefinitions.filter((element) => {
            return !element.hasStructure && element.id !== currentElement?.id;
        })
    }
)



export const fromElementDefinitions = {
    selectDetail,
    selectCreationMode,
    selectElementDefinitions,
    selectElementDefinitionDrafts,
    selectSelectedQueryParam,
    selectCurrentElementDefinition,
    selectFilteredFieldDefinitions,
    selectModified,
    selectDefinitionValidation,
    selectShowVersionHistory,
    selectVersions,
    selectCountVersion,
    selectCurrentSkippingRemovedFields,
    selectHeader,
    selectDetailHeader,
    selectIsViewLoading,
    selectAllowChangeElement,
    selectElementToolbarView,
    selectLastestPublishedVersion,
    selectElementDefinitionRemovedFields,
    selectElementDefinitionAddedField,
    selectDifferences,
    selectFieldDifference,
    selectFormattedFieldChanges,
    selectFieldDefinitionsChanges,
    selectElementDifferences,
    selectDraftCreationInfoFromUrl,
    selectNodesWithErrors,
    selectAllPatternErrors,
    selectStructureValidationResult,
    selectStructureErrorsAndDifferences,
    selectElementsForStructure
};
