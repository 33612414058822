import { createAction, props } from "@ngrx/store";
import { FieldReference, PropertyReference } from "../../patterns/types/click-on-reference-types";
import { NodeInfo } from "../../linked-trees/interfaces/node-info";
import { ElementDefinitionDetail } from "src/app/core/interfaces/element-definition-detail.interface";
import { InlineEditorId } from "../interfaces/field-editor-id";
import { WorkroadsTree } from "src/app/core/interfaces/workroads-tree.interface";
import { EditingField } from "../interfaces/editing-field";

export const clickOnReferenceField = createAction(
    '[Workroads Tree] Click on Reference Field',
    props<{ nodeInfo: NodeInfo } & FieldReference>()
);

export const getElementDefinitionForTreeEditor = createAction(
    '[Workroads Tree API] GET Element Definitions'
);
export const getElementDefinitionForTreeEditorSuccess = createAction(
    '[Workroads Tree API] GET Element Definitions Success',
    props<{ elementDefinitions: ElementDefinitionDetail[] }>()
);
export const getElementDefinitionForTreeEditorFail = createAction(
    '[Workroads Tree API] GET Element Definitions Fail',
    props<{ error: string | null }>()
);


export const setEditingField = createAction(
    '[Field Editor] Set editing field',
    props<EditingField>()
);

export const clickOnReference = createAction(
    '[Autocad Node] Click on Reference',
    props<{ nodeInfo: NodeInfo } & PropertyReference>()
);

export const clickOnValue = createAction(
    '[Autocad Node] Click on Value',
    props<{ value: string }>()
);

export const clickOnReferenceConsumed = createAction(
    '[Global Editor] Click on reference consumed'
);

export const workroadsTreeChange = createAction(
    '[Workroads Tree] Tree Change',
    props<{ tree: WorkroadsTree }>()
);

export const disableEditingField = createAction('[Global Editor] Close editing field');
export const disableEditingFieldInline = createAction(
    '[Field Editor] Close editing field'
);