import { createSelector } from "@ngrx/store";
import { ID } from "src/app/core/definitions/types";
import { fromElementDefinitions } from "../../elements/store/element-definitions.selectors";
import { InlineEditorId } from "../interfaces/field-editor-id";
import { workroadsTreeEditorFeature } from "./shared-nodes.reducer";


const {
    selectCurrentlyEditingField,
    selectCurrentClickOnReferenceInfo,
    selectElementDefinitions,
} = workroadsTreeEditorFeature;


const selectCurrentlyEditingFieldForNode = (nodeId: ID) =>
    createSelector(
        selectCurrentlyEditingField,
        (current): { id: InlineEditorId } | null => {
            if (current && nodeId === current.nodeInfo.id) {
                return { id: current.id };
            }
            return null;
        }
    );

const selectIsInEditionMode = createSelector(
    selectCurrentlyEditingField,
    (current) => !!current?.id
);




export const fromWorkroadsTreeEditor = {
    selectCurrentlyEditingField,
    selectCurrentClickOnReferenceInfo,
    selectElementDefinitions,
    selectIsInEditionMode,
    selectCurrentlyEditingFieldForNode,
}