import { createAction, props } from '@ngrx/store';
import { ID } from '../../../../core/definitions/types';
import { ElementDefinitionDetail } from '../../../../core/interfaces/element-definition-detail.interface';
import { FieldDefinitionDetail } from 'src/app/core/interfaces/field-definition-detail.interface';
import { Status } from 'src/app/core/definitions/enums';
import { HeaderItem } from '../../../layout/interfaces/header-item';

/**
 * elementDefinition prop is for loading the Element stored in the session of the browser
 *
 * @type {*}
 */
export const createElementDefinitionDraft = createAction(
    '[ElementDefinitions] Create ElementDefinition Draft',
    props<{ draft: ElementDefinitionDetail }>()
);

export const loadElementDefinitionDraft = createAction(
    '[ElementDefinitions] Create ElementDefinition Draft',
    props<{ id: ID }>()
);

export const duplicateElementDefinition = createAction(
    '[ElementDefinitions] Duplicate ElementDefinition ',
    props<{ draft: ElementDefinitionDetail }>()
);

export const deleteElementDefinitionDraft = createAction(
    '[ElementDefinitions] Delete ElementDefinition Draft',
    props<{ id: ID }>()
);

export const selectElementDefinition = createAction(
    '[ElementDefinitions] Select ElementDefinition'
);
export const setDraft = createAction(
    '[ElementDefinitions] Set ElementDefinition',
    props<{ elementDefinition: ElementDefinitionDetail | null }>()
);

export const saveElementDefinition = createAction(
    '[ElementDefinitions] Save ElementDefinition',
    props<{ message?: string; status: Status }>()
);

export const deleteElementDefinitionOrDraft = createAction(
    '[ElementDefinitions] Delete ElementDefinition or Draft',
    props<{ id: ID; historyCollection?: ID }>()
);

export const navigateToElementDefinitionList = createAction(
    '[ElementDefinitions] Navigate to list'
);

export const navigateToElementDefinition = createAction(
    '[ElementDefinitions] Navigate to element definition',
    props<{ id: ID; historyCollection?: ID; forceReload?: boolean }>()
);

export const elementDefinitionHeaderChange = createAction(
    '[ElementDefitions] Change in element header definition',
    props<{ elementDefitionHeader: HeaderItem }>()
);

export const elementChangesDiscard = createAction(
    '[ElementDefinitions] Discard changes in element defition'
);

export const elementChangesDiscardDraft = createAction(
    '[ElementDefinitions] Discard changes in draft element defition'
);

export const elementChangesDiscardExisting = createAction(
    '[ElementDefinitions] Discard changes in existing element defition'
);

export const updateListFieldDefinitionsInCurrentElement = createAction(
    '[ElementDefinitions API] FieldDefinitions to ElementDefinition',
    props<{ fieldDefinitions: FieldDefinitionDetail[] }>()
);

export const updateFieldDefinitionInCurrentElement = createAction(
    '[ElementDefinitions API] Modify values of field in ElementDefinition',
    props<{ fieldDefinition: FieldDefinitionDetail }>()
);

export const changeUnicityElement = createAction(
    '[ElementDefinitions API] Modify unicity in ElementDefinition',
    props<{ unicity: boolean; fieldDefinitions: FieldDefinitionDetail[] }>()
);

export const changeCountableElement = createAction(
    '[ElementDefinitions API] Modify countable in ElementDefinition',
    props<{ isCountable: boolean }>()
);

export const changeAdmitsChildrenElement = createAction(
    '[ElementDefinitions API] Modify admitsChildren in ElementDefinition',
    props<{ admitsChildren: boolean }>()
);


export const changeShowVersionHistory = createAction(
    '[ElementDefinitions] Modify Version History',
    props<{ enable: boolean }>()
);

export const resetModuleState = createAction('[ElementDefinitions] Reset Module State');
