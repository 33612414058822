import {
    AfterViewInit,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild
} from '@angular/core';
import * as ace from 'ace-builds';
import { Ace, createEditSession } from 'ace-builds';
import * as LangTools from 'ace-builds/src-noconflict/ext-language_tools';
import { createFlowMode } from './mode/flow-mode';
import 'src/assets/flow-assets/flow-theme';

import { FlowCompletions } from './mode/flow-completions';
import { FlowTokenIterator } from './mode/flow-token-iterator';
import { ID } from '../../../core/definitions/types';
import { AutocompleteCadData } from '../../../modules/shared-nodes/interfaces/autocomplete-cad-data';
import Editor = Ace.Editor;
import Token = Ace.Token;
import EditSession = Ace.EditSession;
import { isFlowMode } from './mode/utils';

const OPTIONS: Partial<Ace.EditorOptions> = {
    autoScrollEditorIntoView: true,
    copyWithEmptySelection: true,
    maxLines: 24,
    enableSnippets: true,
    enableBasicAutocompletion: true,
    enableLiveAutocompletion: true,
    showPrintMargin: false,
    fontSize: 16,
    showLineNumbers: false,
    showGutter: false,
    scrollPastEnd: true,
    highlightActiveLine: false,
    fontFamily: 'monospace'
};

@Component({
    selector: 'app-ace-editor',
    templateUrl: './ace-editor.component.html',
    styleUrls: ['./ace-editor.component.scss']
})
export class AceEditorComponent implements AfterViewInit, OnChanges {
    @Input() inline?: boolean = false;
    @Input() editorId!: ID;
    @Input() nodeAutocompleteContent!: AutocompleteCadData;
    @Input() defaultExpression?: string;

    @Output() editorChange = new EventEmitter<string>();

    @ViewChild('editor') editor?: ElementRef<HTMLElement>;

    completer: FlowCompletions = new FlowCompletions(new FlowTokenIterator());

    session?: EditSession;

    DEBUG = false;

    // DEBUG FIELDS
    currentlySelectedToken?: Token;
    currentState?: string;

    get value(): string | undefined {
        return this.session?.getValue();
    }

    setValue(value: string): void {
        this._aceEditor?.setValue(value, 1);
    }

    /**
     * setValue will reset the undo manager but ctrl z after
     * it will clear the editor. Calling this each time we do
     * set value will fix that but since we share values frequently
     * between inline editor and global editor maybe we don't want to
     * miss some undo history.
     *
     * Solution: Call this method when needed from the parent component
     *
     * Solutions that did not work: oding this on init
     * https://stackoverflow.com/a/30987198
     * this react ace issue https://github.com/securingsincity/react-ace/issues/339
     *
     */
    resetUndoManager(): void {
        this._aceEditor?.getSession().getUndoManager().reset();
    }

    addTextInCursor(value: string): void {
        this._aceEditor?.insert?.(value);
        this._aceEditor?.focus();
    }

    ngAfterViewInit(): void {
        this.session = createEditSession(
            this.value ?? '',
            createFlowMode(this.completer)
        );
        const editor = this._aceEditor;
        editor?.setSession(this.session);
        if (!this.session || !editor) return;
        editor.setOptions({
            ...OPTIONS,
            maxLines: this.inline ? 1 : OPTIONS.maxLines,
            minLines: this.inline ? undefined : OPTIONS.maxLines,
            wrap: true
        });

        this.setEventConfigs(this.session, editor);
        // If something fails this can fix it: this.session.renderer.attachToShadowRoot();
        editor.setTheme('ace/theme/flow_theme');
        this.setCustomAutocomplete();
        editor.focus();
        editor.navigateLineEnd();
        editor.setAutoScrollEditorIntoView(false);
    }

    /**
     * This will allow autocomplete to give live results using the flow completer
     * @param editor
     * @private
     */
    private setCustomAutocomplete() {
        if (this._aceEditor) {
            this._aceEditor.completers = [this.completer];
        }
        LangTools.addCompleter(this.completer);
        LangTools.enableBasicAutocompletion = true;
        const isWordOrPeriod = (args: any) => /^[\w.]$/.test(args);
        const editor = this._aceEditor;

        editor?.commands.on('afterExec', function (e) {
            if (e.command.name == 'insertstring' && isWordOrPeriod(e.args)) {
                editor.execCommand('startAutocomplete');
            }
        });
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.nodeAutocompleteContent) {
            this.completer.updateContext(this.nodeAutocompleteContent);
        }
    }

    get isOverriden() {
        return this.defaultExpression && this.value !== this.defaultExpression;
    }

    private setEventConfigs(session: EditSession, editor: Editor) {
        // The input event will only react to the DOM changes (will not emit in setValue)
        editor.on('input', () => {
            // this.updateDefaultExpression();
            this.editorChange.emit(this.value);
            this.setModeAccordingToValue(this.value, session);
        });
        if (this.DEBUG) {
            // On token click we will display the token information as a json
            editor.on('click' as any, () => {
                const pos = this._aceEditor?.getCursorPosition() ?? { row: 0, column: 0 };
                const token = session.getTokenAt(pos.row, pos.column);
                this.currentlySelectedToken = token ?? undefined;
                this.currentState = session.getState(pos.row);
            });
        }

        if (this.inline) {
            editor.commands.bindKey('Enter|Shift-Enter', 'null' as any);
        }
    }

    currentMode?: 'FLOW' | 'TEXT' = 'TEXT';
    setModeAccordingToValue(value: string | undefined, session: Ace.EditSession) {
        if (isFlowMode(value)) {
            if (this.currentMode === 'FLOW') return;
            session.setMode(createFlowMode(this.completer));
            this.currentMode = 'FLOW';
        } else {
            session.setMode('ace/mode/text');
            this.currentMode = 'TEXT';
        }
    }

    get _aceEditor(): Editor | null {
        if (!this.editor) {
            return null;
        }
        return ace.edit(this.editor.nativeElement);
    }

}
