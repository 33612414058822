import { ColumnDefinition } from 'src/app/core/interfaces/column-definition.interface';
import { Field } from 'src/app/core/interfaces/field.interface';
import { Element } from 'src/app/core/interfaces/import-element-content.interface';
import { BuildReport } from 'src/app/core/resources/build-resource-service/build.resource.service';
import { SortableObject, sortBy } from 'src/app/shared/helpers/sort-definition';
import { Build } from '../../core/interfaces/build.interface';
import { ImportElementNode } from '../../core/interfaces/import-element-node.interface';
import { Node } from '../linked-trees/interfaces/node';
import { addCustomRootToTree, getInternalTree } from '../linked-trees/utils/list-tree';
import {
    CODE_NAME,
    CUSTOM_FUNCTION,
    labels,
    NULL_LABEL,
    QUANTITY
} from './components/report-filters/constants';
import {
    ColumnIdentifier,
    ReportDefinition
} from './interfaces/report-definition.interface';

import { FieldDataType, ReportView } from 'src/app/core/definitions/enums';
import { ViewDetail } from 'src/app/core/interfaces/view-detail.interface';
import { FilterBase } from 'src/app/shared/components/flow-filter-builder/filter-base';
import { FilterTypeGroup } from 'src/app/shared/components/flow-filter-builder/flow-filter-builder.component';
import {
    insertInList,
    removeFromList,
    updateInList
} from 'src/app/shared/helpers/reducer-lists-helpers';
import { flattenTree } from '../../core/helpers/pattern-utils';
import { ExternalNode } from '../linked-trees/interfaces/external-node';
import { temporalReplacementElement } from '../views/utils/filter-builder.utils';
import { CodeNameFilter } from './components/report-filters/code-name-filter';
import { CustomFunctionFilter } from './components/report-filters/custom-function-filter';
import { PropertyFilter } from './components/report-filters/property-filter';
import {
    CODE_NAME_COLUMN_CODE_NAME,
    CODE_NAME_COLUMN_NAME,
    getCodeName,
    getQuantity,
    QUANTITY_COLUMN_CODE_NAME,
    QUANTITY_COLUMN_NAME
} from './constants/columns';
import { IMPORT_ELEMENT_TREE_ID } from './constants/import-tree-id';
import { DEFAULT_REPORT_DEFINITION_LIST } from './constants/report-default.constant';
import {
    GroupData,
    groupDataToSimpleArray,
    simpleArrayToGroupData
} from './interfaces/group-data.interface';
import { ReportFilter } from './interfaces/report-filter.interface';
import { ImportElementTreeAdapterService } from './services/import-element-tree-adapter.service';

export type BuildTreeList = {
    build: Build;
    list: Element[];
    tree: ImportElementNode[];
    reportDefinitions: ReportDefinition[];
};

/**
 * Retrieves the list of columns, their definitions, and the report list from the given list view.
 *
 * The columns will be all the fields found in each element of the listView.
 *
 * @param listView - The list view containing Othe elements.
 * @returns An object containing the report list, columns, and columns definitions.
 */
export function getList(listView: Element[]): {
    reportList: Node[];
    columns: ColumnIdentifier[];
    columnsDefinitions: Record<ColumnIdentifier, ColumnDefinition>;
} {
    const columns: ColumnIdentifier[] = [CODE_NAME_COLUMN_NAME, QUANTITY_COLUMN_NAME];
    const columnsDefinitions: Record<ColumnIdentifier, ColumnDefinition> = {
        [CODE_NAME_COLUMN_NAME]: {
            fieldName: CODE_NAME_COLUMN_NAME,
            codeName: CODE_NAME_COLUMN_CODE_NAME
        },
        [QUANTITY_COLUMN_NAME]: {
            fieldName: QUANTITY_COLUMN_NAME,
            codeName: QUANTITY_COLUMN_CODE_NAME,
            columnType: 'number'
        }
    };

    const elementFieldAnalyzed: Set<string> = new Set<string>();

    function processElementForColumns(element: Element) {
        if (!elementFieldAnalyzed.has(element.elementDefinitionName)) {
            element.fields.forEach((field) => {
                const { fieldDefinitionId, fieldDefinitionName } = field;
                if (!columns.includes(fieldDefinitionId)) {
                    columns.push(fieldDefinitionId);
                    columnsDefinitions[fieldDefinitionId] = {
                        fieldName: fieldDefinitionName,
                        codeName: field.codeName,
                        columnType: typeof field.value
                    };
                }
            });
            elementFieldAnalyzed.add(element.elementDefinitionName);
        }
    }

    const reportList: Node[] = listView.map((element) => {
        processElementForColumns(element);
        elementFieldAnalyzed.add(element.elementDefinitionName); // CHECK THIS
        return {
            id: '',
            rootId: IMPORT_ELEMENT_TREE_ID,
            content: element
            // hasErrorsOrWarnings: hasErrorsOrWarnings(element)
        } as Node;
    });
    return { reportList, columns, columnsDefinitions };
}

export function getTree(tree: ImportElementNode[]) {
    const adaptedTree = new ImportElementTreeAdapterService().adapt(tree);
    const withCustomRoot = addCustomRootToTree(adaptedTree);
    const reportTree = getInternalTree(
        withCustomRoot ?? [],
        IMPORT_ELEMENT_TREE_ID
    ).treeAsList;
    return reportTree;
}

export function getTreeFromBuild(build: Build) {
    return new ImportElementTreeAdapterService().adapt(build.elementsForest);
}

export function getFilterValues(build: Build) {
    const tree = getTree(build.elementsForest);
    const flatTree = flattenTree(tree);

    const codeNamesSet = new Set<string>();
    const propertiesValues = new Map<
        string,
        { options: Set<string>; label: string; key: string; dataType: FieldDataType }
    >();

    propertiesValues.set(QUANTITY, {
        options: new Set<string>(),
        label: labels[QUANTITY],
        key: QUANTITY,
        dataType: FieldDataType.Number
    });

    for (const node of flatTree) {
        const content = node.content as Element;
        if (content.codeName) {
            codeNamesSet.add(content.codeName);
        }
        content.fields.forEach((field) => {
            if (!propertiesValues.has(field.codeName)) {
                propertiesValues.set(field.codeName, {
                    options: new Set<string>(),
                    label: field.fieldDefinitionName,
                    key: field.codeName,
                    dataType: field.dataType
                });
            }
            propertiesValues
                .get(field.codeName)!
                .options.add(field.value?.toString() ?? NULL_LABEL);
        });
    }
    return {
        codeNames: [...codeNamesSet],
        properties: propertiesValues
    };
}

export function downloadExcel(response: { file: Blob; name: string }) {
    const blob = new Blob([response.file], { type: '.xlsx' });
    const downloadURL = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = downloadURL;
    link.download = `${response.name}.xlsx`;
    link.click();
    link.remove();
}

export function convertNodeToRecord(node: Node): SortableObject {
    const fieldsObject = node.content.fields.reduce(
        (prev: Record<ColumnIdentifier, unknown>, current: Field) => ({
            ...prev,
            ...{ [current.fieldDefinitionId]: current.value }
        }),
        {}
    );
    const properties = {
        [CODE_NAME_COLUMN_NAME]: getCodeName(node.content),
        [QUANTITY_COLUMN_NAME]: getQuantity(node.content)
    };

    return { ...fieldsObject, ...properties };
}

export function sortGroupsByColumn(
    reportList: GroupData<Node>[] | null,
    column: ColumnIdentifier,
    by: 'asc' | 'desc'
): GroupData<Node>[] | null {
    // Simplified version
    if (!reportList?.length) return reportList;
    const simpleArray = groupDataToSimpleArray(reportList);
    const sorted = sortNodesByColumn(simpleArray, column, by);
    return sorted ? simpleArrayToGroupData(sorted) : null;
}

export function sortNodesByColumn(
    reportList: Node[],
    column: ColumnIdentifier,
    by: 'asc' | 'desc'
): Node[] | null {
    if (!reportList?.length) return reportList;
    const asc = by === 'asc';
    const skipRow = (node: Node) => {
        const currentField: Field = node.content.fields.find(
            (field: Field) => field.fieldDefinitionId === column
        );
        if (!currentField) return true;
        return (
            currentField.errors.length > 0 ||
            currentField.warnings.length > 0 ||
            currentField.value === null ||
            currentField.value === undefined
        );
    };

    let toSort: Node[] = [];
    const nonSortedValues: Node[] = [];

    if (
        Array.from<ColumnIdentifier>([
            CODE_NAME_COLUMN_NAME,
            QUANTITY_COLUMN_NAME
        ]).includes(column)
    ) {
        toSort = [...reportList]; // We don't skip codeNames or quantities, (no errors in quantities ?)
    } else {
        reportList.forEach((a: Node) => {
            if (skipRow(a)) {
                nonSortedValues.push(a);
            } else {
                toSort.push(a);
            }
        });
    }

    toSort.sort((a, b) =>
        asc
            ? sortBy(convertNodeToRecord(a), convertNodeToRecord(b), column)
            : sortBy(convertNodeToRecord(b), convertNodeToRecord(a), column)
    );
    return [...toSort, ...nonSortedValues];
}

export function transformBuildResponse(buildResponse: BuildReport): BuildTreeList {
    if (!buildResponse?.reportDefinitions.length) {
        const defaultReport = {
            ...DEFAULT_REPORT_DEFINITION_LIST,
            name: 'Report 1',
            order: 0,
            reportView: ReportView.Table
        };
        buildResponse.reportDefinitions.push(defaultReport);
    }
    return {
        build: buildResponse.build,
        list: buildResponse.tableView,
        tree: buildResponse.treeView,
        reportDefinitions: buildResponse.reportDefinitions
    };
}

export function createQueryFromFilters(filters: ReportFilter[]) {
    if (filters.length === 0) return '';
    if (filters.length === 1) return temporalReplacementElement(filters[0].query ?? '');
    // Use parenthesis in expression until is fixed in backend
    return filters.reduce((prev, current, currentIdx) => {
        const temporalElementReplacement = temporalReplacementElement(
            current.query ?? ''
        );
        if (!prev) return `(${temporalElementReplacement})`;
        return `${prev} && (${temporalElementReplacement})`;
    }, '');
}

export function getFiltersInfo(build: Build | null): {
    controls: { [p: string]: FilterBase<any> };
    groups: FilterTypeGroup[];
} {
    if (!build) return { controls: {}, groups: [] };
    const values = getFilterValues(build);
    const controls: { [key: string]: FilterBase<any> } = {
        CODE_NAME: new CodeNameFilter(
            values.codeNames.map((item) => ({ label: item, value: item }))
        ),
        CUSTOM_FUNCTION: new CustomFunctionFilter()
    };
    const propertiesOptions: string[] = [];
    values.properties.forEach((property) => {
        propertiesOptions.push(property.key);
        controls[property.key] = new PropertyFilter(
            property.key,
            property.label,
            property.dataType,
            [...property.options].map((item) => ({
                label: item ? item : 'EMPTY',
                value: item
            }))
        );
    });

    const groups: FilterTypeGroup[] = [
        {
            label: CODE_NAME_COLUMN_NAME,
            options: [CODE_NAME]
        },
        {
            label: 'Properties',
            options: propertiesOptions
        },
        {
            label: 'Custom',
            options: [CUSTOM_FUNCTION]
        }
    ];

    return {
        controls,
        groups
    };
}

export type ColumnTableInformation = {
    hiddenColumns: ColumnIdentifier[];
    allColumnsFromFilteredReport: ColumnIdentifier[]; // Columns from the report, will be used to build the menu
    columnsToShow: ColumnIdentifier[]; // Columns to be shown in the table in the right order
};

/**
 * 
    1. Handles order
    2. Handles hidden columns
    3. Handles removing empty columns ?
 * @param columnsFromReport Columns from the filtered report
 * @param order Order definition. Can have columns that are not in the report and vice-versa. 
 * We need to handle this case by removing the columns that are not in the report and then add the missing columns. 
 * @param hidden Hidden columns
 */
export function getColumnsToBeShown(
    columnsFromReport: ColumnIdentifier[],
    order: ColumnIdentifier[],
    hidden: ColumnIdentifier[]
) {
    const allColumnsFromFilteredReport = new Set(columnsFromReport);
    const isOutdatedInOrder = (column: ColumnIdentifier) =>
        !allColumnsFromFilteredReport.has(column);

    const columns: ColumnIdentifier[] = [];
    // Remove the outdated columns
    for (const column of order) {
        if (!isOutdatedInOrder(column)) {
            columns.push(column);
        }
    }

    // Add the missing columns at the end
    for (const column of columnsFromReport) {
        if (!columns.includes(column)) {
            columns.push(column);
        }
    }

    // Remove the hidden columns
    const columnsToShow = columns.filter((column) => !hidden.includes(column));
    return {
        hiddenColumns: hidden,
        columnsToShow,
        allColumnsFromFilteredReport: [...columnsToShow, ...hidden] // we rebuild this to show the correct order
    };
}

export function addReportDefinition(view: ViewDetail, name: string) {
    const newReportDefinition: ReportDefinition = {
        ...DEFAULT_REPORT_DEFINITION_LIST,
        order: view.reportDefinitions.length ?? 0,
        name
    };

    const newReports = insertInList(view.reportDefinitions, newReportDefinition);
    return {
        ...view,
        reportDefinitions: newReports
    };
}

export function removeReport(view: ViewDetail, index: number) {
    if (view.reportDefinitions.length === 1) {
        return view;
    }

    return {
        ...view,
        reportDefinitions: removeFromList(view.reportDefinitions, { index })
    };
}

export function updateReportDefinition(
    view: ViewDetail,
    updatedItem: Partial<ReportDefinition>,
    index: number
): ViewDetail {
    return {
        ...view,
        reportDefinitions: updateInList(view.reportDefinitions, updatedItem, { index })
    };
}
