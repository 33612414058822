import { HeaderItem } from 'src/app/modules/layout/interfaces/header-item';
import { ID } from '../definitions/types';

export interface ElementDefinitionSummary {
    id: ID;
    historyId?: ID;
    name: string;
    admitsChildren: boolean;
    description: string;
    codeName: string;
    isCountable: boolean;
    unicity: boolean;
    hasStructure: boolean;
}

export const NULL_ELEMENT_DEFINITION_SUMMARY: ElementDefinitionSummary = {
    id: null as any,
    historyId: undefined,
    name: '',
    admitsChildren: false,
    description: '',
    codeName: '',
    isCountable: false,
    unicity: false,
    hasStructure: false
};


export const elementDefinitionSummaryFromDetail = (detail: ElementDefinitionSummary, header?: HeaderItem): ElementDefinitionSummary => {
    return {
        id: detail.id,
        historyId: detail.historyId,
        name: header?.name ?? detail.name,
        description: header?.description ?? detail.description,
        codeName: header?.codeName ?? detail.codeName,
        admitsChildren: detail.admitsChildren,
        isCountable: detail.isCountable,
        unicity: detail.unicity,
        hasStructure: detail.hasStructure
    };
}