import { createFeature, createReducer, on } from '@ngrx/store';
import * as elementDefinitionsActions from './actions/element-definitions.action';
import * as elementDefinitionsApiActions from './actions/element-definitions-api.action';
import { ElementDefinitionDetail } from '../../../core/interfaces/element-definition-detail.interface';
import { ElementDefinitionSummary } from '../../../core/interfaces/element-definition.summary';
import { FieldDefinitionDetail } from 'src/app/core/interfaces/field-definition-detail.interface';
import { ID } from 'src/app/core/definitions/types';
import { Status } from 'src/app/core/definitions/enums';
import { ElementHistoryItem } from 'src/app/core/interfaces/element-history-item.interface';
import { cloneDeep } from 'lodash';
import { UsageInfo } from 'src/app/core/interfaces/validation-result.interface';
import { HeaderItem } from '../../layout/interfaces/header-item';
import { ElementDifferences } from 'src/app/core/interfaces/element-differences.interface';
import { discardUnsavedChangesAndNavigate } from '../../../store/actions/root.actions';
import * as sharedNodesActions from "src/app/modules/shared-nodes/store/shared-nodes.actions";
import { modifyStructure } from '../helpers/structure.helpers';
import { NULL_WORKROADS_TREE } from 'src/app/core/interfaces/workroads-tree.interface';
import { treeHasErrors } from 'src/app/core/helpers/pattern-utils';
import { TreeValidationState } from 'src/app/core/interfaces/general-validation-result.interface';

export interface ElementDefinitionsState {
    detail: ElementDefinitionDetail | null;
    current: ElementDefinitionDetail | null;
    elementDefinitionHistory: ElementHistoryItem[];
    creationMode: boolean;
    elementDefinitionDrafts: ElementDefinitionDetail[];
    elementDefinitions: ElementDefinitionSummary[];
    fieldDefinitions: FieldDefinitionDetail[];
    modified: boolean;
    detailHeader: HeaderItem | null;
    fieldDefinitionValidations: {
        [key: ID]: UsageInfo[];
    };
    showVersionHistory: boolean;
    isLoadingDetail: boolean;
    isLoadingList: boolean;
    elementDifferences: ElementDifferences | null;
    structureHasErrors: boolean;

    /**
     * The result of the structure validation
     */
    structureValidationResult: TreeValidationState | null;

    /**
     * Errors (400 or 500) on the structure validation
     */
    errorsOnValidation: string[];
}

export const initialState: ElementDefinitionsState = {
    detail: null,
    current: null,
    elementDefinitionHistory: [],
    creationMode: false,
    elementDefinitions: [],
    elementDefinitionDrafts: [],
    fieldDefinitions: [],
    modified: false,
    detailHeader: null,
    fieldDefinitionValidations: {},
    showVersionHistory: false,
    isLoadingDetail: false,
    isLoadingList: false,
    elementDifferences: null,
    structureHasErrors: false,
    errorsOnValidation: [],
    structureValidationResult: null
};

export const elementDefinitionsFeature = createFeature({
    name: 'elementDefinitions',
    reducer: createReducer(
        initialState,
        on(elementDefinitionsApiActions.getElementDefinitions, (state) => {
            return { ...state, elementDefinitions: [], isLoadingList: true };
        }),
        on(
            elementDefinitionsApiActions.getElementDefinitionsSuccess,
            (state, { elementDefinitions, elementDefinitionDrafts }) => ({
                ...state,
                elementDefinitionDrafts,
                elementDefinitions,
                elementDefinitionHistory: [],
                isLoadingList: false
            })
        ),
        on(elementDefinitionsApiActions.getElementDefinitionsFail, (state) => ({
            ...state,
            isLoadingList: false
        })),
        on(
            elementDefinitionsActions.duplicateElementDefinition,
            elementDefinitionsActions.createElementDefinitionDraft,
            (state, { draft }) => {
                return {
                    ...state,
                    current: null,
                    elementDefinitionDrafts: [draft, ...state.elementDefinitionDrafts],
                    elementDefinitionHistory: [],
                    showVersionHistory: false,
                    detailHeader: null,
                    creationMode: true,
                    modified: true
                };
            }
        ),
        on(elementDefinitionsActions.deleteElementDefinitionDraft, (state, { id }) => ({
            ...state,
            elementDefinitionDrafts: state.elementDefinitionDrafts.filter(
                (p) => p.id !== id
            )
        })),
        on(elementDefinitionsApiActions.deleteElementDefinition, (state, { id }) => ({
            ...state,
            isLoadingDetail: true
        })),
        on(
            elementDefinitionsApiActions.deleteElementDefinitionSuccess,
            (state, { id }) => ({
                ...state,
                detail: null,
                current: null,
                modified: false,
                fieldDefinitionValidations: {},
                elementDefinitions: state.elementDefinitions.filter((p) => p.id !== id),
                isLoadingDetail: false
            })
        ),
        on(elementDefinitionsApiActions.deleteElementDefinitionFail, (state) => ({
            ...state,
            isLoadingDetail: false
        })),
        on(elementDefinitionsApiActions.getElementDefinitionById, (state) => {
            return {
                ...state,
                detail: null,
                current: null,
                creationMode: false,
                elementDefinitionHistory: [],
                modified: false,
                fieldDefinitionValidations: {},
                isLoadingDetail: true,
                detailHeader: null,
                elementDifferences: null
            };
        }),
        on(
            elementDefinitionsApiActions.getElementDefinitionByIdSuccess,
            (state, { elementDefinition, isDraft }) => {
                const elementClone = cloneDeep(elementDefinition);
                return {
                    ...state,
                    elementDefinitionDrafts: isDraft
                        ? [elementClone, ...state.elementDefinitionDrafts]
                        : state.elementDefinitionDrafts,
                    detail: elementDefinition,
                    current: elementClone,
                    isLoadingDetail: false,
                    creationMode: isDraft ?? false,
                    modified: isDraft ?? false
                };
            }
        ),
        on(elementDefinitionsApiActions.getElementDefinitionByIdFail, (state) => ({
            ...state,
            current: null,
            detail: null,
            creationMode: false,
            modified: false,
            isLoadingDetail: false
        })),
        on(
            elementDefinitionsApiActions.setElementDefinitionHistory,
            (state, { elementDefinitionHistory }) => {
                return {
                    ...state,
                    elementDefinitionHistory
                };
            }
        ),
        on(elementDefinitionsActions.setDraft, (state, { elementDefinition }) => ({
            ...state,
            detail: elementDefinition,
            current: cloneDeep(elementDefinition),
            creationMode: true,
            modified: true,
            detailHeader: null,
            fieldDefinitionValidations: {},
            elementDifferences: null
        })),
        on(elementDefinitionsApiActions.upsertElementDefinition, (state) => {
            return {
                ...state,
                isLoadingDetail: true
            };
        }),
        on(
            elementDefinitionsApiActions.upsertElementDefinitionSuccess,
            (state, { draftId, elementDefinition, creationMode }) => {
                const elements = creationMode
                    ? [...state.elementDefinitions, elementDefinition]
                    : state.elementDefinitions;
                return {
                    ...state,
                    detail: elementDefinition,
                    current: cloneDeep(elementDefinition),
                    creationMode: false,
                    modified: false,
                    fieldDefinitionValidations: {},
                    elementDefinitions: elements,
                    elementDefinitionDrafts: state.elementDefinitionDrafts.filter(
                        (p) => p.id !== draftId
                    ),
                    isLoadingDetail: false,
                    detailHeader: null
                };
            }
        ),
        on(elementDefinitionsApiActions.upsertElementDefinitionFail, (state) => {
            return {
                ...state,
                isLoadingDetail: false
            };
        }),
        on(
            elementDefinitionsActions.elementDefinitionHeaderChange,
            (state, { elementDefitionHeader }) => {
                const newElement = {
                    ...state.current!,
                    status: Status.Draft
                }


                return {
                    ...state,
                    detailHeader: elementDefitionHeader,
                    modified: true,
                    current: {
                        ...newElement,
                        structure: modifyStructure(state.current?.structure ?? NULL_WORKROADS_TREE, newElement, elementDefitionHeader)
                    } as ElementDefinitionDetail
                };
            }
        ),
        on(elementDefinitionsActions.elementChangesDiscardDraft, (state) => ({
            ...state,
            elementDefinitionDrafts: state.elementDefinitionDrafts.filter(
                (draft) => draft.id !== state.current?.id
            ),
            detail: null,
            current: null,
            modified: false,
            fieldDefinitionValidations: {},
            detailHeader: null
        })),
        on(
            elementDefinitionsApiActions.getFieldDefinitionsSuccess,
            (state, { fieldDefinitions }) => {
                return {
                    ...state,
                    fieldDefinitions
                };
            }
        ),
        on(
            elementDefinitionsActions.updateListFieldDefinitionsInCurrentElement,
            (state, { fieldDefinitions }) => {

                const newElement = {
                    ...state.current!,
                    status: Status.Draft,
                    fieldDefinitions: fieldDefinitions,
                    structure: NULL_WORKROADS_TREE
                } as ElementDefinitionDetail;

                return {
                    ...state,
                    current: {
                        ...newElement,
                        structure: modifyStructure(
                            state.current?.structure ?? NULL_WORKROADS_TREE,
                            newElement)
                    },
                    modified: true
                };
            }
        ),
        on(
            elementDefinitionsActions.changeUnicityElement,
            (state, { unicity, fieldDefinitions }) => {
                const newElement = {
                    ...state.current!,
                        status: Status.Draft,
                        fieldDefinitions,
                    unicity,
                } as ElementDefinitionDetail;

                return {
                    ...state,
                    current: {
                        ...newElement,
                        structure: modifyStructure(state.current?.structure ?? NULL_WORKROADS_TREE, newElement)
                    },
                    modified: true
                };
            }
        ),
        on(elementDefinitionsActions.changeCountableElement, (state, { isCountable }) => {
            const newElement = {
                ...state.current!,
                status: Status.Draft,
                isCountable,
            } as ElementDefinitionDetail;


            return {
                ...state,
                current: {
                    ...newElement,
                    structure: modifyStructure(state.current?.structure ?? NULL_WORKROADS_TREE, newElement)
                },
                modified: true
            };
        }),
        on(
            elementDefinitionsActions.changeAdmitsChildrenElement,
            (state, { admitsChildren }) => {
                return {
                    ...state,
                    current: {
                        ...state.current!,
                        status: Status.Draft,
                        admitsChildren
                    },
                    modified: true
                };
            }
        ),
        on(
            elementDefinitionsActions.updateFieldDefinitionInCurrentElement,
            (state, { fieldDefinition }) => {
                const newElement = {
                    ...state.current!,
                    status: Status.Draft,
                    fieldDefinitions:
                        state.current?.fieldDefinitions.map((item) =>
                            item.id === fieldDefinition.id ? fieldDefinition : item
                        ) ?? []
                }
                return {
                    ...state,
                    current: {
                        ...newElement,
                        structure: modifyStructure(state.current?.structure ?? NULL_WORKROADS_TREE, newElement)
                    },
                    modified: true
                };
            }
        ),
        on(
            elementDefinitionsApiActions.getCodeNameUsageSuccess,
            (state, { usageInfo, fieldId }) => {
                const fieldDefinitionValidations = {
                    ...state.fieldDefinitionValidations
                };
                fieldDefinitionValidations[fieldId] = usageInfo;
                return {
                    ...state,
                    fieldDefinitionValidations
                };
            }
        ),
        on(elementDefinitionsActions.changeShowVersionHistory, (state, { enable }) => {
            return {
                ...state,
                showVersionHistory: enable
            };
        }),
        on(elementDefinitionsActions.resetModuleState, (state) => {
            return {
                ...initialState
            };
        }),
        on(elementDefinitionsActions.elementChangesDiscard, (state) => {
            return {
                ...state,
                detailHeader: null
            };
        }),
        on(
            elementDefinitionsApiActions.getElementDifferencesSuccess,
            (state, { elementDifferences }) => {
                return {
                    ...state,
                    elementDifferences
                };
            }
        ),
        on(discardUnsavedChangesAndNavigate, (state) => ({
            ...state,
            elementDefinitionDrafts: initialState.elementDefinitionDrafts,
            modified: false
        })),
        on(sharedNodesActions.workroadsTreeChange, (state, { tree }) => ({
            ...state,
            modified: true,
            current: {
                ...state.current!,
                status: Status.Draft,
                structure: tree
            },
            structureHasErrors: treeHasErrors(tree)
        }))
    )
});
