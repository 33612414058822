import { WorkroadsTreeNode } from './workroads-tree-node.interface';

/**
 * This intermediate structures is used by the
 * "Tree Adapters", classes that convert the tree
 * from backend into the tree that is used by the
 * LinkedTreesComponent
 */
export interface WorkroadsTree {
    childrenDefinitions: WorkroadsTreeNode[];
    groupCount: { [key: string]: number };
}


export const NULL_WORKROADS_TREE: WorkroadsTree = {
    childrenDefinitions: [],
    groupCount: {} // how many nodes are in each group, groupIds are guid 
};