import { Component, Input } from '@angular/core';
import { GlobalEditorInfo } from 'src/app/modules/patterns/store/global-editor.selectors';

@Component({
    selector: 'app-global-editor-layout',
    templateUrl: './global-editor-layout.component.html',
    styleUrls: ['./global-editor-layout.component.scss']
})
export class GlobalEditorLayoutComponent {
    @Input() globalEditorInfo!: GlobalEditorInfo;
}
