import { TrackedChange } from "src/app/core/definitions/enums";
import { ElementDefinitionDetail } from "src/app/core/interfaces/element-definition-detail.interface";
import { elementDefinitionSummaryFromDetail } from "src/app/core/interfaces/element-definition.summary";
import { FieldDefinitionDetail } from "src/app/core/interfaces/field-definition-detail.interface";
import { FieldExpressionDefinition } from "src/app/core/interfaces/field-expression-definition.interface";
import { WorkroadsTree } from "src/app/core/interfaces/workroads-tree.interface";
import { uuidWrapper } from "src/app/shared/helpers/uuid";
import { HeaderItem } from "../../layout/interfaces/header-item";

export function createStructureFromElement(elementDefinition: ElementDefinitionDetail): WorkroadsTree {
    return {
        childrenDefinitions: [{
            id: uuidWrapper.uuid(),
            comment: "",
            childrenDefinitions: [],
            elementDefinition: elementDefinitionSummaryFromDetail(elementDefinition),
            fieldExpressions: getFieldExpressions(elementDefinition.fieldDefinitions),
            quantityExpression: "",
            quantityDefaultExpression: "",
            quantityErrors: [],
            quantityWarnings: [],
            disableMode: 0,
            isNodeHidden: false,
            created: false,
            groupId: "",
            groupOrder: 0,
            isGoToNode: false,
            enabledIfErrors: [],
            enabledIfWarnings: []
        }],
        groupCount: {}
    }
}

export function getFieldExpressions(fieldDefinitions: FieldDefinitionDetail[]): FieldExpressionDefinition[] {
    const fieldExpressions = fieldDefinitions
        ? fieldDefinitions.map((item) => ({
            fieldDefinitionId: item.id,
            fieldDefinition: {
                ...item,
                value: item.value ?? ''
            },
            expression: item.value ?? '',
            unicityKind: item.unicityKind,

        })) as FieldExpressionDefinition[]
        : []
    return fieldExpressions;
}

export function modifyStructure(currentStructure: WorkroadsTree, newElement: ElementDefinitionDetail, header?: HeaderItem): WorkroadsTree {
    const [firstChild, ...rest] = currentStructure.childrenDefinitions;

    const fieldDefinitionMap = new Map(
        newElement.fieldDefinitions.map(field => [field.id, field])
    );

    // Use `Map` for field expressions
    const firstChildFieldExpressionsMap = new Map(
        firstChild.fieldExpressions.map(expression => [expression.fieldDefinitionId, expression])
    );

    const newFieldExpressions = getFieldExpressions(newElement.fieldDefinitions);

    // keep expressions while making sure we modify unicity and all other possible fields
    const result = newFieldExpressions.map(item => {
        const existingFieldExpression = firstChildFieldExpressionsMap.get(item.fieldDefinitionId);
        if (!existingFieldExpression) {
            return item;
        }
        return {
            ...item,
            expression: existingFieldExpression.expression,
            defaultExpression: existingFieldExpression.defaultExpression,
            errors: existingFieldExpression.errors,
            warnings: existingFieldExpression.warnings
        }
    }).filter(item => fieldDefinitionMap.get(item.fieldDefinitionId)?.trackedChange != TrackedChange.Deleted)

    const quantityFields = newElement.isCountable ? {
        quantityExpression: firstChild.quantityExpression,
        quantityErrors: firstChild.quantityErrors,
        quantityWarnings: firstChild.quantityWarnings,
    } : {};



    const currentHeader: HeaderItem = header ?? {
        name: firstChild.elementDefinition.name ?? newElement.name,
        description: firstChild.elementDefinition.description ?? newElement.description,
        codeName: firstChild.elementDefinition.codeName ?? newElement.codeName
    }


    return {
        ...currentStructure,
        childrenDefinitions: [{
            ...firstChild,
            elementDefinition: elementDefinitionSummaryFromDetail(newElement, currentHeader),
            fieldExpressions: result,
            ...quantityFields
        }, ...rest]
    }


}